import { SetPagination, SetQueriesObject } from "@/utils/setQueriesObject";

export const getJson = (data) => ({
    id: data.id,
    status: data.status,
    created_at: data.created_at,
    share_count: data.share_count,
    user: data.user
        ? {
              id: data.user.id,
              name: data.user.name,
              bio: data.user.bio,
              name_ar: data.user.name_ar,
              first_name_length: data.user.first_name_length,
              first_name: data.user.first_name,
              last_name: data.user.last_name,
              email: data.user.email,
              email_verified_at: data.user.email_verified_at,
              cell_number: data.user.cell_number,
              city_id: data.user.city_id,
              country_id: data.user.country_id,
              image: data.user.image,
              thumbnail: data.user.thumbnail,
          }
        : null,
    video: data.video
        ? {
              id: data.video.id,
              url: data.video.url,
              mime: data.video.mime_type,
              thumbnail: data.video.thumbnail,
          }
        : null,
});
export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta);
    data = data.map((user) => getJson(user));
    return { data, pagination };
};
export const setData = (data) => {
    const formData = {
        title: data.title,
        text: data.text,
        file_batch_id: data.file_batch_id,
    };
    return formData;
};
export const setQuery = (data) => SetQueriesObject(data);
